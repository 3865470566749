import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

const propTypes = {
  url: PropTypes.string.isRequired,
};

const Redirect = ({ url }) => {
  const router = useRouter();
  useEffect(() => {
    router.push(url);
  }, [router, url]);
  return <></>;
};

Redirect.propTypes = propTypes;
export default Redirect;
