/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import SharedLibs from 'wizarding-world-web-shared';
import useTranslation from 'next-translate/useTranslation';
import { useUserContext } from '@contexts/user-context';
import useCountry from '@hooks/useCountry';
import { NEXT_LOCALE_COOKIE } from '@/src/constants/localization';
import { trackEvent } from '@utils/analytics';
import { UPDATE_PROFILE } from '@utils/graphQueries';
import { getSupportedCountryLocale } from '@utils/locales';
import { locales as SUPPORTED_LOCALES } from '../../../i18n';
import Image from '../Image';
import flagEN from './img/flag-en.png';
import flagJA from './img/flag-ja.png';
import flagFR from './img/flag-fr.png';
import flagES from './img/flag-es.png';
import flagDE from './img/flag-de.png';
import s from './LanguagePicker.module.scss';

const localeFlagImages = {
  en: flagEN,
  ja: flagJA,
  fr: flagFR,
  es: flagES,
  de: flagDE,
};

const localeImageSets = SUPPORTED_LOCALES.reduce((imageSetObject, locale) => {
  /* eslint-disable-next-line no-param-reassign */
  imageSetObject[locale] = [
    {
      src: localeFlagImages[locale],
      type: 'image/png',
    },
    {
      src: localeFlagImages[locale],
    },
  ];
  return imageSetObject;
}, {});

const propTypes = {
  pageName: PropTypes.string.isRequired,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
};

const LanguagePicker = ({ pageName, marginTop = 0, marginBottom = 0 }) => {
  const router = useRouter();
  const { lang } = useTranslation('common');
  const { isLoggedIn } = useUserContext();
  const { country } = useCountry('');
  const { pathname, asPath, query } = router;
  const { setCookie } = SharedLibs.utils.cookie;
  const { api: wizardingWorldAPI } = SharedLibs.services;

  const handleLanguageChange = (locale) => {
    if (isLoggedIn) {
      wizardingWorldAPI().mutate({
        mutation: UPDATE_PROFILE,
        variables: {
          locale: getSupportedCountryLocale(locale, country),
        },
      });
    }

    setCookie({
      name: NEXT_LOCALE_COOKIE,
      value: locale,
      expires: 365 * 24 * 60 * 60,
    });
    if (lang !== locale) {
      trackEvent('Language Selected', {
        language: locale,
        location: pageName,
      });
    }
    router.replace({ pathname, query }, asPath, { locale });
  };

  return (
    <div
      className={s.container}
      style={{
        marginTop: `${marginTop}px`,
        marginBottom: `${marginBottom}px`,
      }}
    >
      {SUPPORTED_LOCALES.map((locale) => (
        <div
          key={locale}
          className={[s.localeFlag, lang === locale ? s.active : ''].join(' ')}
          tabIndex="0"
          role="button"
          onClick={() => {
            handleLanguageChange(locale);
          }}
          onKeyDown={(event) => {
            /* istanbul ignore else */
            if (event.key === 'Enter') handleLanguageChange(locale);
          }}
          data-testid={`${locale}-image`}
        >
          <Image imageSet={localeImageSets?.[locale]} contain lazy />
        </div>
      ))}
    </div>
  );
};

LanguagePicker.propTypes = propTypes;
export default LanguagePicker;
