
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import SortingHatIntro from '../../src/pages/SortingHatIntro';
const SortingHatPage = () => <SortingHatIntro />;
async function getStaticProps() {
    return {
        props: {},
        revalidate: Number(process.env.REVALIDATION_TIMEOUT_SHORT) || 60 * 5
    };
}
export default SortingHatPage;

    async function __Next_Translate__getStaticProps__193b66cb13e__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/sorting-hat/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193b66cb13e__ as getStaticProps }
  