import {
  WW_SORTING_HAT,
  WW_SORTING_HAT_DIRECT,
  SORTING_HAT_FIRST_QUESTION,
  SORTING_HAT,
  MY_PATRONUS,
  WW_PATRONUS,
  NEXT_PATRONUS,
  WW_WAND,
  PORTRAIT_MAKER,
} from './routes';

// Auth flow step names
export const AUTH_FLOW_LOGIN_STEP = 'Login';
export const AUTH_FLOW_REGISTER_STEP = 'Register';
export const AUTH_FLOW_REGISTER_VERIFY_STEP = 'Register Verify';
export const AUTH_FLOW_PARENTAL_CONSENT_STEP = 'Parental Consent';
export const AUTH_FLOW_POTTERMORE_MIGRATE_STEP = 'Pottermore Migrate';
export const AUTH_FLOW_POTTERMORE_REGISTER_STEP = 'Pottermore Register';
export const AUTH_FLOW_POTTERMORE_VERIFY_STEP = 'Pottermore Verify';
export const AUTH_FLOW_NEWSLETTER_OPT_IN_STEP = 'Newsletter Opt-In';

export const AUTH_DESTINATION_SORTING = 'authDestinationSorting';
export const AUTH_DESTINATION_PATRONUS = 'authDestinationPatronus';
export const AUTH_DESTINATION_WAND = 'authDestinationWand';
export const AUTH_DESTINATION_PORTRAIT = 'authDestinationPortrait';
export const AUTH_DESTINATION_POTTERMORE = 'authDestinationPottermore';
export const AUTH_DESTINATION_UNDERAGE = 'authDestinationUnderage';

// Mapping of routes to the "destination" after the auth flow is complete
export const AUTH_DESTINATION_MAP = {
  [WW_SORTING_HAT]: AUTH_DESTINATION_SORTING,
  [WW_SORTING_HAT_DIRECT]: AUTH_DESTINATION_SORTING,
  [SORTING_HAT_FIRST_QUESTION]: AUTH_DESTINATION_SORTING,
  [SORTING_HAT]: AUTH_DESTINATION_SORTING,
  [MY_PATRONUS]: AUTH_DESTINATION_PATRONUS,
  [WW_PATRONUS]: AUTH_DESTINATION_PATRONUS,
  [NEXT_PATRONUS]: AUTH_DESTINATION_PATRONUS,
  [WW_WAND]: AUTH_DESTINATION_WAND,
  [PORTRAIT_MAKER]: AUTH_DESTINATION_PORTRAIT,
};

// line1 = main header between the styled lines
// line2 = secondary header or description
// hasSecondaryHeader = used to determine whether or not to display line2 in the same style as line1
export const AUTH_DESTINATION_COPY = {
  default: {
    line1: 'auth:sign-up',
    line2: 'register:header.magical-features',
    hasSecondaryHeader: false,
  },
  [AUTH_DESTINATION_POTTERMORE]: {
    line1: 'register:header.with-pottermore1',
    line2: 'register:header.with-pottermore2',
    hasSecondaryHeader: true,
  },
  [AUTH_DESTINATION_UNDERAGE]: {
    line1: 'register:header.almost-there',
    line2: 'register:header.enter-details',
    hasSecondaryHeader: false,
  },
  [AUTH_DESTINATION_SORTING]: {
    line1: 'register:header.to-get-sorted',
    hasSecondaryHeader: false,
  },
  [AUTH_DESTINATION_PATRONUS]: {
    line1: 'register:header.sign-up-to',
    line2: 'register:header.reveal-your-patronus',
    hasSecondaryHeader: true,
  },
  [AUTH_DESTINATION_WAND]: {
    line1: 'register:header.sign-up-to',
    line2: 'register:header.discover-your-wand',
    hasSecondaryHeader: true,
  },
  [AUTH_DESTINATION_PORTRAIT]: {
    line1: 'register:header.sign-up-to',
    line2: 'register:header.create-your-portrait',
    hasSecondaryHeader: true,
  },
};
