import SharedLib from 'wizarding-world-web-shared';
import { AUTH_SUCCESS_REDIRECT_PATH, AUTH_SUCCESS_DEFAULT_EXPIRATION } from '@constants/index';
import { AUTH_DESTINATION_MAP, AUTH_DESTINATION_COPY } from '@constants/auth';
import { stripLocaleFromURL } from '@utils/analytics';

export const setAuthSuccessRedirect = (
  redirectPath,
  expiration = AUTH_SUCCESS_DEFAULT_EXPIRATION,
) => {
  const { setCookie } = SharedLib.utils.cookie;
  setCookie({
    name: AUTH_SUCCESS_REDIRECT_PATH,
    value: stripLocaleFromURL(redirectPath),
    expires: expiration,
  });
};

export const getAuthSuccessRedirect = () => {
  const { getCookie } = SharedLib.utils.cookie;
  return getCookie(AUTH_SUCCESS_REDIRECT_PATH) || null;
};

export const eraseAuthSuccessRedirect = () => {
  const { eraseCookie } = SharedLib.utils.cookie;
  eraseCookie(AUTH_SUCCESS_REDIRECT_PATH);
};

export const getAuthDestinationCopy = (destinationOverride = null) => {
  const { getCookie } = SharedLib.utils.cookie;
  const destination = AUTH_DESTINATION_MAP[getCookie(AUTH_SUCCESS_REDIRECT_PATH)];
  const copy = AUTH_DESTINATION_COPY[destinationOverride || destination];
  return copy || AUTH_DESTINATION_COPY.default;
};
